import React from 'react';
import {MDBAnimation} from 'mdbreact';
import {Event} from '../../GAClickEvent';

export default props => (
  <>
    <div className="service-text-banner section-navigation-bar d-none floating-section-nav-bar ">
      <div className="service-section">
        <div className="col-12">
          <div className="row filters-large">
            <div className="col-2 pl-0 pr-0">
              <MDBAnimation type="fadeIn" duration="1s" reveal>
                <div className="toscroll  text-center wow fadeIn" id="slideAdd">
                  <div className="row w-100  d-flex align-items-center mr-1">
                    <div className="col-10 filter-text  pr-0">
                      <a className="smooth-scroll  " href="#clientReviews">
                        <span
                          className="filter-margins-1 float-left"
                          onClick={() =>
                            Event(
                              'Client Reviews',
                              'Client Reviews button is clicked',
                              window.location.pathname
                            )
                          }
                        >
                          Client Reviews
                        </span>
                      </a>
                      <br />
                      <a className="smooth-scroll" href="#services">
                        <span
                          className="filter-margins-2 float-left"
                          onClick={() =>
                            Event(
                              'Technologies',
                              'Technologies button is clicked',
                              window.location.pathname
                            )
                          }
                        >
                          Technologies
                        </span>
                      </a>
                      <br />
                      <a className="smooth-scroll" href="#process">
                        <span
                          className="filter-margins-3 float-left"
                          onClick={() =>
                            Event('Process', 'Process button is clicked', window.location.pathname)
                          }
                        >
                          Process
                        </span>
                      </a>
                      <br />
                    </div>
                    <div className="col-2 chevron">
                      <a href={void 0}>
                        <i className="fa fa-chevron-left" />
                        {/* <img class="withoutRightHover d-none" alt="arrow" src={require('../../assets/images/services/web-development-old/right-arrow.png') !!}"/>
                           <img class="withRightHover d-none" alt="arrowHover" src={require('../../assets/images/services/web-development-old/right-hover-arrow.png') !!}"/>  */}
                      </a>
                    </div>
                  </div>
                </div>
              </MDBAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
