import React from 'react';
import {MDBAnimation} from 'mdbreact';
import {Link} from 'gatsby';
import ClutchReview from './clutchReviews';
import CustomSoftware from './clutchReviews/CustomSoftware';
import PreKickOff from './DetailUnits/PreKickOff';
import KickOff from './DetailUnits/KickOff';
import Increment from './DetailUnits/Increment';
import Delivery from './DetailUnits/Delivery';
import StickCricket from './clutchReviews/StickCricket';
import AndroidDevelopmentB2b from './clutchReviews/AndroidDevelopmentB2b';
import AugmentedIos from './clutchReviews/AugmentedIos';
import PlayDate from './clutchReviews/PlayDate';
import FounderGameDevelopment from './clutchReviews/FounderGameDevelopment';
import SuperBinge from './clutchReviews/SuperBinge';
import LeftNav from './DetailUnits/LeftNav';
import {Helmet} from 'react-helmet';
import {Event} from '../GAClickEvent';

require('./detailservices.css');
const $ = typeof window !== `undefined` ? require('jquery') : null;
const bootstrap = typeof window !== `undefined` ? require('bootstrap') : null;

const BLANK_IMAGE_BASE64 =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

class AppDevelopment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ios: 'active',
      android: '',
      qa: '',
      mobileBackend: '',
      scalability: '',
    };
  }
  componentDidMount() {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
      $('#landing-banner-slider').bind('slide.bs.carousel', function () {
        var caption = $('.active').data('number');
        $('.caption-container *[class^="caption"]').removeClass('d-block');
        $('.caption-container *[class^="caption"]').addClass('d-none');
        $('.caption-container .caption-' + caption).removeClass('d-none');
        $('.caption-container .caption-' + caption).addClass('d-block');
        //TODO: remove it after figuring out why carousel is not working after fixing this bug
        //consol.log('ok')
      });

      $('#pre-kickoff-vertical').fadeIn();
      $('#pre-kickoff').addClass('pre-kickoff-active');
      $('.card').on('click', function (e) {
        var id = $(this).attr('id');
        var description = ['pre-kickoff', 'code', 'increment', 'delivery'];
        description.forEach(function (Id) {
          $('#' + Id + '-description').hide();
          $('#' + Id + '-vertical').hide();
          $('#' + Id).removeClass(Id + '-active');
        });
        $('#' + id + '-description').fadeIn();
        $('#' + id + '-vertical').fadeIn();
        $('#' + id).addClass(id + '-active');
      });

      $(function () {
        $('#capabilitiesSelect li').click(function () {
          $('.tech-section').hide();
          $('#' + $(this).attr('data-value')).slideDown();
        });
      });

      $('.selectTechnology').click(function () {
        $(this).toggleClass('open');
        $('.options', this).toggleClass('open');
      });

      $('.options li').click(function () {
        var selection = $(this).text();
        var dataValue = $(this).attr('data-value');
        $('.selected-option span').text(selection);
        $('.selectTechnology').attr('data-selected-value', dataValue);
      });

      if ($(window).width() <= 1500) {
        $('.iOS-tech-section .col-3').removeClass('col-lg-2');
        $('.iOS-tech-section .col-8').removeClass('col-lg-11');
        $('.android-tech-section .col-3').removeClass('col-lg-2');
        $('.android-tech-section .col-8').removeClass('col-lg-11');
        $('.qatest-tech-section .col-3').removeClass('col-lg-2');
        $('.qatest-tech-section .col-8').removeClass('col-lg-11');
        $('.backend-tech-section .col-3').removeClass('col-lg-2');
        $('.backend-tech-section .col-8').removeClass('col-lg-11');
        $('.scalable-tech-section .col-3').removeClass('col-lg-2');
        $('.scalable-tech-section .col-8').removeClass('col-lg-11');
      }

      $(window).resize(function () {
        if ($(this).width() <= 1500) {
          $('.iOS-tech-section .col-3').removeClass('col-lg-2');
          $('.iOS-tech-section .col-8').removeClass('col-lg-11');
          $('.android-tech-section .col-3').removeClass('col-lg-2');
          $('.android-tech-section .col-8').removeClass('col-lg-11');
          $('.qatest-tech-section .col-3').removeClass('col-lg-2');
          $('.qatest-tech-section .col-8').removeClass('col-lg-11');
          $('.backend-tech-section .col-3').removeClass('col-lg-2');
          $('.backend-tech-section .col-8').removeClass('col-lg-11');
          $('.scalable-tech-section .col-3').removeClass('col-lg-2');
          $('.scalable-tech-section .col-8').removeClass('col-lg-11');
        }
      });

      $('a.smooth-scroll').click(function (event) {
        event.preventDefault();
        $('html, body').animate(
          {
            scrollTop: $($(this).attr('href')).offset().top,
          },
          600
        );
      });

      if ($(window).width() <= 768) {
        $('.solid-section-nav-bar').removeClass('d-none');
      } else {
        $('.solid-section-nav-bar').addClass('d-none');
        if (
          $(window).width() > 1500 &&
          $('.mobile-dev-page').offset() &&
          $('.mobile-dev-page').offset().top - $(window).scrollTop() < 0
        ) {
          $('.floating-section-nav-bar').removeClass('d-none');
        }
      }

      $(document).scroll(function () {
        if ($(window).width() > 768) {
          if (
            $('.mobile-dev-page').offset() &&
            $('.mobile-dev-page').offset().top - $(window).scrollTop() < 0
          ) {
            $('.floating-section-nav-bar').removeClass('d-none');
            setTimeout(function () {
              $('.floating-section-nav-bar .toscroll').removeClass('animated').attr('style', '');
            }, 500);
          } else {
            $('.floating-section-nav-bar').addClass('d-none');
            $('.floating-section-nav-bar .toscroll')
              .addClass('animated')
              .attr('style', 'visibility: visible; animation-name: fadeIn;');
          }
        }
      });
      $(window).resize(function () {
        if ($(window).width() > 768) {
          if (
            $('.mobile-dev-page').offset() &&
            $('.mobile-dev-page').offset().top - $(window).scrollTop() < 0
          ) {
            $('.floating-section-nav-bar').removeClass('d-none');
          }
          $('.solid-section-nav-bar').addClass('d-none');
        } else {
          $('.solid-section-nav-bar').removeClass('d-none');
          $('.floating-section-nav-bar').addClass('d-none');
        }
      });

      $('.filters-large .toscroll .chevron').on('click', function () {
        $('.toscroll .filter-text').slideUp(50, function () {
          $('.filter-text').removeClass('trans');
        });
        $('.filter-text').addClass('trans');
        $('.toscroll .filter-text').slideDown(300);
        $('#slideAdd').toggleClass('i-o-btn');
        $(this).parents('.toscroll').find('.filter-text').toggleClass('d-none');
        $(this).find('i').toggleClass('fa fa-chevron-right');
        $(this).find('i').toggleClass('fa fa-chevron-left');
      });

      if ($(window).width() >= 1600) {
        $('.draftking-phone').addClass('offset-xl-1');
        $('.ander-phone').addClass('offset-xl-1');
      }

      $(window).resize(function () {
        if ($(this).width() >= 1600) {
          $('.draftking-phone').addClass('offset-xl-1');
          $('.ander-phone').addClass('offset-xl-1');
        }
      });
    });

    function addMargin() {
      window.scrollTo(0, window.pageYOffset - 80);
    }
    window.addEventListener('hashchange', addMargin);
  }

  handleSelect = type => {
    if (type === 'ios') {
      this.setState({
        ios: 'active',
        android: '',
        mobileBackend: '',
        qa: '',
        scalability: '',
      });
    } else if (type === 'android') {
      this.setState({
        ios: '',
        android: 'active',
        mobileBackend: '',
        qa: '',
        scalability: '',
      });
    } else if (type === 'backend') {
      this.setState({
        ios: '',
        android: '',
        mobileBackend: 'active',
        qa: '',
        scalability: '',
      });
    } else if (type === 'qa') {
      this.setState({
        ios: '',
        android: '',
        mobileBackend: '',
        qa: 'active',
        scalability: '',
      });
    } else if (type === 'scalability') {
      this.setState({
        ios: '',
        android: '',
        mobileBackend: '',
        qa: '',
        scalability: 'active',
      });
    }
  };

  letGetStartedPopUp = () => {
    $('.modal.lets-get-started-modal').modal();
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bespoke Mobile App Development Services for Startups | Tintash</title>
          <link
            rel="preload"
            href={require('../../assets/images/services/mobile-development/lifeprint.png').default}
            as="image"
            media="(min-width: 992px)"
          />
        </Helmet>
        <LeftNav />

        <section className="mobile-dev-page service-individual-page">
          <div className="container">
            <div className="row  ">
              <div className="col-12 landing-text-container customFadeInDown">
                <h1 className="latoBlack text-center text-white service-heading">
                  Mobile App Development
                </h1>
                {/* Tag line of mobile development */}
                <div className="service-unicorn d-flex align-items-center">
                  <div className="container">
                    <div className="row">
                      <div className="service-text">
                        <span className="latoRegular font-weight-normal">Heavy-lifting for </span>
                        <span className="latoBold">Startups, Unicorns & &nbsp;</span>
                        <span className="latoBold">Fortune 500s</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-none d-sm-none d-md-none d-lg-block p-0">
                <div className="customFadeInUp">
                  <div id="landing-banner-container">
                    <div
                      id="landing-banner-slider"
                      className="carousel slide"
                      data-ride="carousel"
                      data-interval={5000}
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item item landing-slider active" data-number={2}>
                          <picture>
                            <source media="(max-width: 991.9px)" srcSet={BLANK_IMAGE_BASE64} />
                            <img
                              alt="First slide"
                              className="app-shot"
                              src={
                                require('../../assets/images/services/mobile-development/lifeprint.png')
                                  .default
                              }
                            />
                          </picture>
                        </div>
                        <div className="carousel-item item landing-slider" data-number={1}>
                          <picture>
                            <source media="(max-width: 991.9px)" srcSet={BLANK_IMAGE_BASE64} />
                            <img
                              alt="First slide"
                              className="app-shot"
                              src={
                                require('../../assets/images/services/mobile-development/ander-landing.png')
                                  .default
                              }
                            />
                          </picture>
                        </div>
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#landing-banner-slider"
                        data-slide="prev"
                      >
                        <img
                          alt="left"
                          className="app-shot"
                          src={require('../../assets/images/misc/service-coursel-left.svg').default}
                        />
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#landing-banner-slider"
                        data-slide="next"
                      >
                        <img
                          alt="right"
                          className="app-shot"
                          src={
                            require('../../assets/images/misc/service-coursel-right.svg').default
                          }
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-app-logo">
          <div className="caption-container">
            <div className="container">
              <div className="row">
                <div className="landing-carousel-caption col-lg-12">
                  <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                    <div
                      className="d-none d-lg-block d-inline wow slideInUp"
                      data-wow-duration="1s"
                    >
                      <div className="caption-2 d-none">
                        <h3 className="latoBlack text-center caption-heading">
                          <b>Ander</b>
                        </h3>
                        <div className="tech-logo">
                          <img
                            src={
                              require('../../assets/images/services/tools-services-logo/kotlin-android.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Kotlin"
                          />
                          <img
                            className="center-image-align"
                            src={
                              require('../../assets/images/services/tools-services-logo/ios.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Swift"
                          />
                          <img
                            className="center-image-align"
                            src={
                              require('../../assets/images/services/tools-services-logo/ROR.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Ruby on Rails"
                          />
                        </div>
                        <div className="contain-carousel-caption text-center">
                          <p>
                            The first of its kind total engagement approach that empowers employers
                            and employees alike to achieve meaningful personal and professional
                            improvements using the evidence-based, digitally enabled platform.{' '}
                          </p>
                        </div>
                      </div>
                      <div className="caption-1">
                        <h3 className="latoBlack text-center caption-heading">
                          <b>Lifeprint</b>
                        </h3>
                        <div className="tech-logo">
                          <img
                            src={
                              require('../../assets/images/services/tools-services-logo/kotlin-android.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Kotlin"
                          />
                          <img
                            className="center-image-align"
                            src={
                              require('../../assets/images/services/tools-services-logo/ios.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Swift"
                          />
                          <img
                            className="center-image-align"
                            src={
                              require('../../assets/images/services/tools-services-logo/Firebase.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Firebase"
                          />
                          <img
                            className="center-image-align"
                            src={
                              require('../../assets/images/services/tools-services-logo/python.svg')
                                .default
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Python"
                          />
                        </div>
                        <div className="contain-carousel-caption text-center">
                          <p className="latoRegular">
                            An instant photo printer that not only allows you to print live videos,
                            but also share those printed videos with your friends, family, and fans
                            all over the world.
                          </p>
                        </div>
                      </div>
                    </div>
                  </MDBAnimation>
                </div>
              </div>
            </div>
          </div>
          <MDBAnimation type="slideInUp" duration={'1s'} reveal>
            <div className="client-app-logo-section wow slideInUp" data-wow-duration="1s">
              <div className="image-container container mobile-app-logos">
                <div className="img-fluid ember-logo" />
                <div className="img-fluid draft-king-logo" />
                <div className="img-fluid lifeprint-logo" />
                <div className="img-fluid bby-logo" />
                <div className="img-fluid ander-logo" />
              </div>
            </div>
          </MDBAnimation>
        </section>
        <MDBAnimation type="slideInUp" duration={'0.7s'}>
          <section className="client-logos d-lg-none">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="row center-logos">
                    <div className="col-6 col-md-4 col-lg-3 text-center">
                      <div className="my-4 px-4 w-100">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/home-page/client-logos/bedbathbeyond.png')
                              .default
                          }
                          alt="bed bath and beyond logo"
                        />
                      </div>
                    </div>
                    <div className="col-6  col-md-4 col-lg-3 text-center">
                      <div className="my-3 px-4 w-100">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/home-page/client-logos/drafkings.png')
                              .default
                          }
                          alt="bedbathbeyond"
                        />
                      </div>
                    </div>
                    <div className="col-6  col-md-4 col-lg-3 text-center">
                      <div className="my-4 px-4 w-100">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/home-page/client-logos/lifeprint.png')
                              .default
                          }
                          alt="life print logo"
                        />
                      </div>
                    </div>
                    <div className="col-6  col-md-4 col-lg-3 text-center">
                      <div className="my-4 px-4 w-100">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/client-logo/ander updated.png')
                              .default
                          }
                          alt="ander logo"
                        />
                      </div>
                    </div>
                    <div className="col-6  col-md-4 col-lg-3 text-center">
                      <div className="my-4 px-4 w-100">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/home-page/client-logos/embrwave.png')
                              .default
                          }
                          alt="ember"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </MDBAnimation>
        <section className="featured-proj-section draft-king-section" data-wow-duration="1s">
          <div className="featured-project w-100 mx-0 px-0">
            <div className="row container-padding py-0">
              <div className="col-lg-4 pr-0 py-0 feature-project-description-container container-first">
                <div className="row px-0" style={{height: '100%', margin: 'auto'}}>
                  <div className=" col-12 px-0">
                    <MDBAnimation type="slideInUp" reveal>
                      <div className="project-heading wow slideInUp">
                        <img
                          className="d-none d-sm-block"
                          src={
                            require('../../assets/images/services/feature-project/logo-white-draft-kings.svg')
                              .default
                          }
                          alt="img"
                        />
                        <img
                          className="d-block d-sm-none"
                          src={
                            require('../../assets/images/services/feature-project/logo-white-draft-kings-mobile.svg')
                              .default
                          }
                          alt="img"
                        />
                      </div>
                    </MDBAnimation>

                    <MDBAnimation type="slideInUp" reveal>
                      <p className="latoRegular project-description text-padding-desktop my-0 pr-0  wow slideInUp">
                        Fantasy Sports <br />
                        for Cash
                      </p>
                    </MDBAnimation>
                    <MDBAnimation type="slideInUp" reveal>
                      <div className="horizontal-line my-5 wow slideInUp d-none d-lg-flex" />
                      <p className="style-description latoRegular text-padding-desktop px-0 mb-5 wow slideInUp">
                        DraftKings is the market leader in the fantasy sports for cash market and
                        offers daily and weekly mixed sports for real cash prizes.
                      </p>
                    </MDBAnimation>
                    <MDBAnimation type="slideInUp" reveal>
                      <div className="flex app-store wow slideInUp">
                        <a
                          style={{textDecoration: 'none'}}
                          href="https://itunes.apple.com/us/app/draftkings-fantasy-sports/id710535379?mt=8"
                          target="_blank"
                        >
                          <img
                            src={
                              require('../../assets/images/services/feature-project/apple2.png')
                                .default
                            }
                          />
                        </a>
                        <a
                          style={{textDecoration: 'none'}}
                          href="https://play.google.com/store/apps/details?id=com.draftkings.dklive&hl=en_US"
                          target="_blank"
                        >
                          <img
                            src={
                              require('../../assets/images/services/feature-project/google2.png')
                                .default
                            }
                          />
                        </a>
                      </div>
                    </MDBAnimation>
                  </div>
                </div>
              </div>
              <div className="draftking-phone move-left col-lg-5 featured-project-screens offset-lg-2 px-0 d-none  d-lg-flex justify-content-end">
                <MDBAnimation type="slideInUp" reveal>
                  <div className="container-fluid mx-0 mobile-img wow slideInUp android-phone-dimension">
                    <div className="screen-android  d-flex justify-content-center align-items-center android-phone-dimension">
                      <picture>
                        <source media="(max-width: 991.9px)" srcSet={BLANK_IMAGE_BASE64} />
                        <img
                          className
                          src={
                            require('../../assets/images/services/mobile-development/draftking-first.svg')
                              .default
                          }
                          alt="img"
                        />
                      </picture>
                    </div>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex flex-wrap justify-content-center caret-row mx-0">
          <i className="fa fa-caret-down draftkings-caret" />
        </div>
        <section className="review-section">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <MDBAnimation type="fadeInUp" reveal>
                  <div className="d-flex project-owner-container justify-content-center align-items-center mt-0 wow fadeInUp">
                    <img
                      width="60px"
                      height="60px"
                      src={
                        require('../../assets/images/services/mobile-development/jordan.png')
                          .default
                      }
                    />
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" reveal>
                  <div className="d-flex justify-content-center pt-0 wow fadeInUp">
                    <p className="featured-project-quote">
                      <q>We could not have been so successful in the mobile market without them!</q>
                    </p>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" reveal>
                  <div className="d-flex justify-content-center wow fadeInUp">
                    <p className="latoRegular head-name">Jordan Mendell</p>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" reveal>
                  <div className="d-flex justify-content-center wow fadeInUp">
                    <p className="latoRegular head-position">
                      Head of Mobile &amp; Social Projects at{' '}
                      <span style={{color: '#595d65'}}>DraftKings</span>
                    </p>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-proj-section ander-bg" data-wow-duration="1s">
          <div className="featured-project w-100 mx-0 px-0">
            <div className="row container-padding py-0">
              <div className="col-lg-4 pr-0 feature-project-description-container container-first py-0">
                <div className="row px-0" style={{height: '100%', margin: 'auto'}}>
                  <div className=" col-12 px-0">
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <div className=" project-heading wow slideInUp">
                        <img
                          className="d-none d-sm-block"
                          src={
                            require('../../assets/images/services/mobile-development/ander.png')
                              .default
                          }
                        />
                        <img
                          className="d-block d-sm-none"
                          src={
                            require('../../assets/images/services/mobile-development/ander-logo-mobile.svg')
                              .default
                          }
                          alt="img"
                        />
                      </div>
                    </MDBAnimation>
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <p className="latoRegular project-description text-padding-desktop my-0 pr-0  wow slideInUp">
                        Unifying Life <br /> &amp; Work
                      </p>
                      <div className="horizontal-line my-5 wow slideInUp d-none d-lg-flex" />
                      <p className="style-description latoRegular text-padding-desktop px-0 mb-5 wow slideInUp">
                        The first of its kind total engagement approach that empowers employers and
                        employees alike to achieve meaningful personal and professional improvements
                        using the evidence-based, digitally enabled platform.
                      </p>
                    </MDBAnimation>
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <div className="flex wow slideInUp app-store">
                        <a
                          style={{textDecoration: 'none'}}
                          href="https://apps.apple.com/us/app/ander/id1421258752"
                          target="_blank"
                        >
                          <img
                            src={
                              require('../../assets/images/services/feature-project/apple2.png')
                                .default
                            }
                          />
                        </a>
                        <a
                          style={{textDecoration: 'none'}}
                          href="https://play.google.com/store/apps/details?id=com.anderplatform.app&hl=en_US"
                          target="_blank"
                        >
                          <img
                            src={
                              require('../../assets/images/services/feature-project/google2.png')
                                .default
                            }
                          />
                        </a>
                      </div>
                    </MDBAnimation>
                  </div>
                </div>
              </div>

              <div className="ander-phone move-left col-lg-5 featured-project-screens offset-lg-2 px-0 d-none d-lg-flex justify-content-end">
                <MDBAnimation type="slideInUp" reveal>
                  <div className="container-fluid mobile-img iphone-img mx-0 iphone-dimension wow slideInUp">
                    <div className="screen-iphone d-flex justify-content-center align-items-center iphone-dimension">
                      <picture>
                        <source media="(max-width: 991.9px)" srcSet={BLANK_IMAGE_BASE64} />
                        <img
                          className
                          src={
                            require('../../assets/images/services/mobile-development/ander-feature-2a.png')
                              .default
                          }
                          alt="img"
                        />
                      </picture>
                    </div>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex flex-wrap justify-content-center caret-row mx-0">
          <i className="fa fa-caret-down ander-caret" />
        </div>
        <section className="review-section">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex project-owner-container justify-content-center align-items-center mt-0 wow fadeInUp">
                    <img
                      width="60px"
                      height="60px"
                      src={
                        require('../../assets/images/services/mobile-development/ander-coo.png')
                          .default
                      }
                    />
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex justify-content-center pt-0 wow fadeInUp">
                    <p className="featured-project-quote">
                      <q>
                        Tintash quickly understood our goals, constructively scoped the project, and
                        built a team to deliver.
                      </q>
                    </p>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex justify-content-center wow fadeInUp">
                    <p className="latoRegular head-name">Chris Toepker</p>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex justify-content-center wow fadeInUp">
                    <p className="latoRegular head-position">
                      COO, Head of Product at <span style={{color: '#595d65'}}>Ander</span>
                    </p>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </section>
        <section className="featured-proj-section lifeprint-bg" data-wow-duration="1s">
          <div className="featured-project w-100 mx-0 px-0">
            <div className="row container-padding py-0">
              <div className="col-lg-4 pr-0 feature-project-description-container container-first py-0">
                <div className="row px-0" style={{height: '100%', margin: 'auto'}}>
                  <div className=" col-12 px-0">
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <div className=" project-heading wow slideInUp">
                        <img
                          className="d-none d-sm-block"
                          src={
                            require('../../assets/images/services/mobile-development/lifeprintlogo-web.png')
                              .default
                          }
                          alt="img"
                        />
                        <img
                          className="d-block d-sm-none"
                          src={
                            require('../../assets/images/services/mobile-development/lifeprint-logo-mobile.png')
                              .default
                          }
                          alt="img"
                        />
                      </div>
                    </MDBAnimation>
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <p className="latoRegular project-description text-padding-desktop my-0 pr-0  wow slideInUp">
                        AR Photo &amp;
                        <br />
                        Video Printer
                      </p>
                      <div className="horizontal-line my-5 wow slideInUp d-none d-lg-flex" />
                      <p className="style-description latoRegular text-padding-desktop px-0 lifeprint-margin wow slideInUp">
                        Lifeprint is an instant photo printer that not only allows you to print live
                        videos, but also share those printed videos with your friends, family, and
                        fans all over the world.
                      </p>
                    </MDBAnimation>
                  </div>
                </div>
              </div>
              <div className="ander-phone move-left col-lg-5 featured-project-screens offset-lg-2 px-0 d-none d-lg-flex justify-content-end">
                <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                  <div className="container-fluid mobile-img iphone-lifeprint-img  mx-0 iphone-dimension wow slideInUp">
                    <div className="screen-lifeprint d-flex justify-content-center align-items-center iphone-dimension">
                      <picture>
                        <source media="(max-width: 991.9px)" srcSet={BLANK_IMAGE_BASE64} />
                        <img
                          className
                          src={
                            require('../../assets/images/services/mobile-development/iphone-lifeprint.png')
                              .default
                          }
                          alt="img"
                        />
                      </picture>
                    </div>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex flex-wrap justify-content-center caret-row mx-0">
          <i className="fa fa-caret-down lifeprint-caret" />
        </div>
        <section className="review-section">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex project-owner-container justify-content-center align-items-center mt-0 wow fadeInUp">
                    <img
                      width="60px"
                      height="60px"
                      src={
                        require('../../assets/images/services/mobile-development/patrick.png')
                          .default
                      }
                    />
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex justify-content-center pt-0 wow fadeInUp">
                    <p className="featured-project-quote">
                      <q>
                        They have a strong, capable, and very hardworking team that is skilled in
                        developing Client Apps and associated Cloud Services. They were extremely
                        easy to work with, responsive to feedback, and clearly took great pride in
                        their work.
                      </q>
                    </p>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex justify-content-center wow fadeInUp">
                    <p className="latoRegular head-name">Patrick Cosgrove</p>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration={'1s'} reveal>
                  <div className="d-flex justify-content-center wow fadeInUp">
                    <p className="latoRegular head-position">
                      CTO at <span style={{color: '#595d65'}}>Lifeprint</span>
                    </p>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </section>
        <section className="talk-to-our-team d-flex justify-content-center align-items-center">
          <MDBAnimation type="slideInUp" duration={'1s'} reveal>
            <Link
              className="btn btn-outline-light-service latoBold wow slideInUp button-layout"
              onClick={() =>
                Event(
                  "Let's Get Started",
                  "Let's Get Started button is clicked",
                  window.location.pathname
                )
              }
              to="/contact"
            >
              Let's Get Started
            </Link>
          </MDBAnimation>
        </section>
        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
          <section
            className="verified-reviews d-lg-block d-none wow slideInUp"
            data-wow-duration="1s"
            id="clientReviews"
          >
            <div className="featured-project-screens">
              <div className="screen-mobile">
                <div className="verified-reviews-title container">
                  <h2 className="latoBlack">Verified Third Party Client Reviews</h2>
                  <p className="latoRegular mb-0">
                    {' '}
                    <a
                      target="_blank"
                      href="https://clutch.co/"
                      className="text-light-grey"
                      style={{textDecoration: 'underline'}}
                    >
                      Clutch.co
                    </a>{' '}
                    is a leading platform that conducts independent, in-depth interviews of clients
                    of tech services companies.
                  </p>
                </div>
                {/*?php 
              $data = [
                'slides' =*/} [
                {/*'partials.verified_reviews._stick_cricket',
              'partials.verified_reviews._android_development_b2b',
              'partials.verified_reviews._augmented_ios',
              'partials.verified_reviews._playdate',
              'partials.verified_reviews._founder_game_development',
              'partials.verified_reviews._custom_software',
              'partials.verified_reviews._super_binge'
              ]
              ];
              ?&gt;
              @include('partials._3d_slider', $data)*/}
                <ClutchReview
                  slides={[
                    'StickCricket',
                    'AndroidDevelopmentB2b',
                    'AugmentedIos',
                    'PlayDate',
                    'FounderGameDevelopment',
                    'CustomSoftware',
                    'SuperBinge',
                  ]}
                />
              </div>
            </div>
          </section>
        </MDBAnimation>
        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
          <section className="verified-reviews d-lg-none wow slideInUp" data-wow-duration="1s">
            <div className="featured-project-screens">
              <div className="screen-mobile">
                <div className="verified-reviews-title container">
                  <h2 className="latoBlack">Verified Third Party Client Reviews</h2>
                  <p className="latoRegular">
                    {' '}
                    These reviews are by{' '}
                    <a
                      target="_blank"
                      href="https://clutch.co/"
                      className="text-light-grey"
                      style={{textDecoration: 'underline'}}
                    >
                      Clutch.co
                    </a>
                    , a leading platform that conducts independent, in-depth interviews of clients
                    of tech services companies.
                  </p>
                </div>
                <div
                  id="verifiedReviews"
                  className="verified-height carousel slide"
                  data-ride="carousel"
                  data-interval={40000}
                >
                  <a
                    className="carousel-control-prev d-none d-md-block d-lg-none"
                    href="#verifiedReviews"
                    data-slide="prev"
                  >
                    <img
                      alt="right"
                      src={
                        require('../../assets/images/services/verified-reviews/left.svg').default
                      }
                    />
                  </a>
                  <div className="verified-height">
                    <div className="carousel-inner mobile-review-card">
                      <div className="carousel-item item active">
                        <StickCricket />
                      </div>
                      <div className="carousel-item item">
                        <AndroidDevelopmentB2b />
                      </div>
                      <div className="carousel-item item">
                        <AugmentedIos />
                      </div>
                      <div className="carousel-item item">
                        <PlayDate />
                      </div>
                      <div className="carousel-item item">
                        <FounderGameDevelopment />
                      </div>
                      <div className="carousel-item item">
                        <CustomSoftware />
                      </div>
                      <div className="carousel-item item">
                        <SuperBinge />
                      </div>
                    </div>
                  </div>
                  <a
                    className="carousel-control-next d-none d-md-block d-lg-none"
                    href="#verifiedReviews"
                    data-slide="next"
                  >
                    <img
                      alt="right"
                      src={
                        require('../../assets/images/services/verified-reviews/right.svg').default
                      }
                    />
                  </a>
                </div>
              </div>
              <div className="d-flex justify-content-center d-none d-sm-block d-md-none">
                <a className="d-block d-sm-none" href="#verifiedReviews" data-slide="prev">
                  <MDBAnimation type="slideInUp" reveal>
                    <button className="carousel-buttons mr-1 wow slideInUp btn" aria-pressed="true">
                      <i className="fa fa-chevron-left" style={{color: '#4fb0f7'}} />
                    </button>
                  </MDBAnimation>
                </a>
                <a className="d-block d-sm-none" href="#verifiedReviews" data-slide="next">
                  <MDBAnimation type="slideInUp" reveal>
                    <button className="carousel-buttons ml-1 wow slideInUp btn" aria-pressed="true">
                      <i className="fa fa-chevron-right ml-0" style={{color: '#4fb0f7'}} />
                    </button>
                  </MDBAnimation>
                </a>
              </div>
            </div>
          </section>
        </MDBAnimation>

        <section className="service-text-banner">
          <div id="services" className="service-capabilities">
            <div className="d-lg-block d-none">
              <div className="container-fluid px-0 technologies-section">
                <div
                  id="#myCar"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="false"
                >
                  <div className="col-12 px-0">
                    <MDBAnimation type="slideInUp" reveal>
                      <h2 className="tech-heading latoBlack mb-0">App Development Technologies</h2>
                    </MDBAnimation>
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <ul className="nav nav-tabs myTab" id="myTab">
                        <li
                          className={`px-0 myItem  col-2 ${this.state.ios}`}
                          data-target="#myCar"
                          onClick={() => this.handleSelect('ios')}
                          data-slide-to={0}
                        >
                          <a
                            className="px-3 w-100 h-100 d-flex align-items-center justify-content-center myLink active"
                            id="ios-tab"
                            data-toggle="tab"
                            href="#iOSDev"
                            role="tab"
                            aria-controls="iOS"
                            aria-selected="true"
                          >
                            iOS Development
                          </a>
                        </li>
                        <li
                          className={`px-0 myItem  col-2 ${this.state.android}`}
                          data-target="#myCar"
                          onClick={() => this.handleSelect('android')}
                          data-slide-to={1}
                        >
                          <a
                            className="px-3 w-100 h-100 d-flex align-items-center justify-content-center myLink"
                            id="android-tab"
                            data-toggle="tab"
                            href="#android"
                            role="tab"
                            aria-controls="Android"
                            aria-selected="false"
                          >
                            Android Development
                          </a>
                        </li>
                        <li
                          className={`px-0 myItem  col-2 ${this.state.qa}`}
                          data-target="#myCar"
                          onClick={() => this.handleSelect('qa')}
                          data-slide-to={2}
                        >
                          <a
                            className="px-3 w-100 h-100 d-flex align-items-center justify-content-center myLink"
                            id="qa-tab"
                            data-toggle="tab"
                            href="#QATest"
                            role="tab"
                            aria-controls="QATest"
                            aria-selected="false"
                          >
                            QA &amp; Testing
                          </a>
                        </li>
                        <li
                          className={`px-0 myItem  col-2 ${this.state.mobileBackend}`}
                          data-target="#myCar"
                          onClick={() => this.handleSelect('backend')}
                          data-slide-to={3}
                        >
                          <a
                            className="px-3 w-100 h-100 d-flex align-items-center justify-content-center myLink"
                            id="backend-tab"
                            data-toggle="tab"
                            href="#backendEng"
                            role="tab"
                            aria-controls="Backend"
                            aria-selected="false"
                          >
                            Mobile Backend Engineering
                          </a>
                        </li>
                        <li
                          className={`px-0 myItem  col-2 ${this.state.scalability}`}
                          data-target="#myCar"
                          onClick={() => this.handleSelect('scalability')}
                          data-slide-to={4}
                        >
                          <a
                            className="px-3 w-100 h-100 d-flex align-items-center justify-content-center myLink"
                            id="scalability-tab"
                            data-toggle="tab"
                            href="#scalability"
                            role="tab"
                            aria-controls="Scalability"
                            aria-selected="false"
                          >
                            Scalability &amp; Interoperability
                          </a>
                        </li>
                      </ul>
                    </MDBAnimation>
                    <div className="technologies-container col-12">
                      <div className="technologies-bgcontainer px-0 mx-0">
                        <div className="row mx-0">
                          <div className="carousel-inner">
                            <div
                              className="iOS-tech-section carousel-item item active"
                              data-number={0}
                              id="iOSDev"
                              aria-labelledby="iOS"
                            >
                              <div className="d-flex">
                                <div className="col-3 col-lg-2 set-card-margins">
                                  <div className="image-container">
                                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                      <div style={{paddingTop: '80px'}}>
                                        <img
                                          className="img-fluid"
                                          src={
                                            require('../../assets/images/services/mobile-development/tech-logo.svg')
                                              .default
                                          }
                                        />
                                      </div>
                                    </MDBAnimation>
                                  </div>
                                </div>
                                <div className="col-8 col-lg-11 pl-0 pr-0">
                                  <div className="container ml-0 pl-0 pr-0">
                                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                      <h3 className="cap-headings  wow slideInUp">
                                        iOS Development
                                      </h3>
                                      <p className="cap-text wow slideInUp">
                                        We started iOS development about a decade ago, and haven’t
                                        looked back since. During this time, we have delivered apps
                                        across a number of verticals ranging from IoT, healthcare,
                                        fintech and augmented reality, to social platforms and
                                        educational apps.
                                      </p>
                                    </MDBAnimation>
                                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                      <div className="cap-head mb-4 wow slideInUp">Expertise</div>
                                    </MDBAnimation>
                                    <div className="row shift-right">
                                      <div className="d-flex justify-content-start cap-wrapper">
                                        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Swift</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Objective C</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Reactive Programming</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Architecture Design Patterns</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Analytics</p>
                                          </div>
                                        </MDBAnimation>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="android-tech-section carousel-item item"
                              data-number={1}
                              id="android"
                              aria-labelledby="Android"
                            >
                              <div className="d-flex">
                                <div className="col-3 col-lg-2 set-card-margins">
                                  <div className="image-container">
                                    <div style={{paddingTop: '80px'}}>
                                      <img
                                        className="img-fluid"
                                        src={
                                          require('../../assets/images/services/mobile-development/tech-logo.svg')
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 col-lg-11 pl-0 pr-0">
                                  <div className="container ml-0 pl-0 pr-0">
                                    <h3 className="cap-headings">Android Development</h3>
                                    <p className="cap-text">
                                      We started with Android development when it was all about
                                      plain MVC based Java applications, and have come a long way
                                      since then. Today, we build robust and thoughtfully designed
                                      applications to deliver a holistic user experience, and
                                      leverage maximum efficiency while also using optimum resources
                                      on a wide range of device specifications.
                                    </p>
                                    <div className="cap-head mb-4">Expertise</div>
                                    <div className="row ">
                                      <div className="d-flex justify-content-start cap-wrapper">
                                        <div className="cap-container mt-2">
                                          <p>Java</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Kotlin</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Architecture Design Patterns</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Reactive Programming</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Dependency Injection</p>
                                        </div>

                                        <div className="cap-container mt-2">
                                          <p>Analytics</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="qatest-tech-section carousel-item item"
                              data-number={2}
                              id="QATest"
                              aria-labelledby="QATest"
                            >
                              <div className="d-flex">
                                <div className="col-3 col-lg-2 set-card-margins">
                                  <div className="image-container">
                                    <div style={{paddingTop: '80px'}}>
                                      <img
                                        className="img-fluid"
                                        src={
                                          require('../../assets/images/services/mobile-development/tech-logo.svg')
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 col-lg-11 pl-0 pr-0">
                                  <div className="container ml-0 pl-0 pr-0">
                                    <h3 className="cap-headings">QA &amp; Testing</h3>
                                    <p className="cap-text">
                                      We consider quality assurance and testing absolutely pivotal
                                      to the success of every project we work on. We ensure
                                      end-to-end user satisfaction by involving our team members at
                                      the very beginning of every project. The testing process
                                      includes a combination of automated and manual tests to ensure
                                      your product runs smoothly across devices.
                                    </p>
                                    <div className="cap-head mb-4">Expertise</div>
                                    <div className="row shift-right">
                                      <div className="d-flex justify-content-start cap-wrapper">
                                        <div className="cap-container mt-2">
                                          <p>Functional Testing</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>White and Black Box Testing</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Selenium</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Appium</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Espresso UI Testing</p>
                                        </div>

                                        <div className="cap-container mt-2">
                                          <p>JUnit</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>XC Test</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="backend-tech-section carousel-item item"
                              data-number={3}
                              id="backendEng"
                              aria-labelledby="Backend"
                            >
                              <div className="d-flex">
                                <div className="col-3 col-lg-2 set-card-margins">
                                  <div className="image-container">
                                    <div style={{paddingTop: '80px'}}>
                                      <img
                                        className="img-fluid"
                                        src={
                                          require('../../assets/images/services/mobile-development/tech-logo.svg')
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 col-lg-11 pl-0 pr-0">
                                  <div className="container ml-0 pl-0 pr-0">
                                    <h3 className="cap-headings">Mobile Backend Engineering</h3>
                                    <p className="cap-text" style={{paddingRight: '8%'}}>
                                      Our experience around API development to support native
                                      applications is with Django (Python) and Ruby on Rails
                                      primarily. While developing APIs, and depending on how far
                                      into the life of a project we are, we make sure to focus on
                                      stability, scalability, performance and ease of integration of
                                      the app.
                                    </p>
                                    <div className="cap-head mb-4">Expertise</div>
                                    <div className="row shift-right">
                                      <div className="d-flex justify-content-start cap-wrapper">
                                        <div className="cap-container mt-2">
                                          <p>Python Django</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>RoR</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Node JS</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>JSON</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>REST</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>SSL Encryption</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="scalable-tech-section carousel-item item"
                              data-number={4}
                              id="scalability"
                              aria-labelledby="Scalability"
                            >
                              <div className="d-flex">
                                <div className="col-3 col-lg-2 set-card-margins">
                                  <div className="image-container">
                                    <div style={{paddingTop: '80px'}}>
                                      <img
                                        className="img-fluid"
                                        src={
                                          require('../../assets/images/services/mobile-development/tech-logo.svg')
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 col-lg-11 pl-0 pr-0">
                                  <div className="container ml-0 pl-0 pr-0">
                                    <h3 className="cap-headings">
                                      Scalability &amp; Interoperability
                                    </h3>
                                    <p
                                      className="cap-text"
                                      style={{
                                        paddingBottom: '80px',
                                      }}
                                    >
                                      We design our apps with modularity and a scalable architecture
                                      so that they can be easily integrated with 3rd party Systems
                                      at the time of development or even at a later stage of their
                                      lifecycle. Technology is ever-evolving and it's really
                                      important to adapt to the changing technical landscape, to
                                      this end we follow best practices/industry standards while
                                      designing our systems to make them future proof.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MDBAnimation type="slideInUp" reveal>
              <div className="wow slideInUp d-lg-none" data-wow-duration="1s">
                <div className="technologies-section container-fluid px-0">
                  <div className="col-12 px-0">
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <h2 className="myheading latoBold mb-0  wow slideInUp">Technologies</h2>
                    </MDBAnimation>
                    <div className="technologies-container col-12">
                      <div>
                        <div className="dropdown-container panel-title text-left d-none">
                          <select id="selectSomething" className="custom-select button-text">
                            <option
                              className="button-text button-bg"
                              value="collapse-ios"
                              data-toggle="collapse"
                              href="#collapse-ios"
                            >
                              iOS Development
                            </option>
                            <option className="button-text button-bg" value="collapse-android">
                              Android Development
                            </option>
                            <option className="button-text button-bg" value="collapse-qatest">
                              QA &amp; Testing
                            </option>
                            <option className="button-text button-bg" value="collapse-backend">
                              Mobile Backend Engineering
                            </option>
                            <option className="button-text button-bg" value="collapse-scalable">
                              Scalability &amp; Interoperability
                            </option>
                          </select>
                        </div>
                        <ul className="selectTechnology" data-selected-value="collapse-ios">
                          <li className="button-text selected-option">
                            <span>iOS Development</span>
                            <ul className="options" id="capabilitiesSelect">
                              <li className="button-text" data-value="collapse-ios">
                                iOS Development
                              </li>
                              <li className="button-text" data-value="collapse-android">
                                Android Development
                              </li>
                              <li className="button-text" data-value="collapse-qatest">
                                QA &amp; Testing
                              </li>
                              <li className="button-text" data-value="collapse-backend">
                                Mobile Backend Engineering
                              </li>
                              <li className="button-text" data-value="collapse-scalable">
                                Scalability &amp; Interoperability
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        id="collapse-ios"
                        className="tech-section panel-collapse collapse"
                        style={{display: 'block'}}
                      >
                        <div className="container panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div className="process-description">
                                <div className="d-flex d-row">
                                  <div className="container pl-0 pr-0">
                                    <MDBAnimation type="slideInUp" reveal>
                                      <h3 className="cap-headings  wow slideInUp">
                                        iOS Development
                                      </h3>
                                      <p className="cap-text  wow slideInUp">
                                        We started iOS development about a decade ago, and haven’t
                                        looked back since. During this time, we have delivered apps
                                        across a number of verticals ranging from IoT, healthcare,
                                        fintech and augmented reality, to social platforms and
                                        educational apps.
                                      </p>
                                    </MDBAnimation>
                                    <MDBAnimation type="slideInUp" reveal>
                                      <div className="cap-head mb-2  wow slideInUp">Expertise</div>
                                    </MDBAnimation>
                                    <div className="row d-flex justify-content-center">
                                      <div className="d-flex justify-content-center pr-0 cap-wrapper">
                                        <MDBAnimation type="slideInUp" reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Swift</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Objective C</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Reactive Programming</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Architecture Design Patterns</p>
                                          </div>
                                        </MDBAnimation>
                                        <MDBAnimation type="slideInUp" reveal>
                                          <div className="cap-container mt-2  wow slideInUp">
                                            <p>Analytics</p>
                                          </div>
                                        </MDBAnimation>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapse-android"
                        className="panel-collapse collapse tech-section"
                        style={{display: 'none'}}
                      >
                        <div className="container panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div className="process-description">
                                <div className="d-flex d-row">
                                  <div className="container pl-0 pr-0">
                                    <h3 className="cap-headings ">Android Development</h3>
                                    <p className="cap-text  ">
                                      We started with Android development when it was all about
                                      plain MVC based Java applications, and have come a long way
                                      since then. Today, we build robust and thoughtfully designed
                                      applications to deliver a holistic user experience, and
                                      leverage maximum efficiency while also using optimum resources
                                      on a wide range of device specifications.
                                    </p>
                                    <div className="cap-head mb-2   ">Expertise</div>
                                    <div className="row d-flex justify-content-center">
                                      <div className="d-flex justify-content-center pr-0 cap-wrapper">
                                        <div className="cap-container mt-2">
                                          <p>Java</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Kotlin</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Reactive Programming</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Dependency Injection</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Architecture Design Patterns</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Analytics</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapse-qatest"
                        className="panel-collapse collapse tech-section"
                        style={{display: 'none'}}
                      >
                        <div className="container panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div className="process-description">
                                <div className="d-flex d-row">
                                  <div className="container pl-0 pr-0">
                                    <h3 className="cap-headings ">QA &amp; Testing</h3>
                                    <p className="cap-text">
                                      We consider quality assurance and testing absolutely pivotal
                                      to the success of every project we work on. We ensure
                                      end-to-end user satisfaction by involving our team members at
                                      the very beginning of every project. The testing process
                                      includes a combination of automated and manual tests to ensure
                                      your product runs smoothly across devices.
                                    </p>
                                    <div className="cap-head mb-2">Expertise</div>
                                    <div className="row d-flex justify-content-center">
                                      <div className="d-flex justify-content-center pr-0 cap-wrapper">
                                        <div className="cap-container mt-2 ">
                                          <p>Functional Testing</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>White and Black Box Testing</p>
                                        </div>
                                        <div className="cap-container mt-2 ">
                                          <p>Selenium</p>
                                        </div>
                                        <div className="cap-container mt-2 ">
                                          <p>Appium</p>
                                        </div>
                                        <div className="cap-container mt-2 ">
                                          <p>Espresso UI Testing</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>JUnit</p>
                                        </div>
                                        <div className="cap-container mt-2  ">
                                          <p>XC Test</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapse-backend"
                        className="panel-collapse collapse tech-section"
                        style={{display: 'none'}}
                      >
                        <div className="container panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div className="process-description">
                                <div className="d-flex d-row">
                                  <div className="container pl-0 pr-0">
                                    <h3 className="cap-headings">Mobile Backend Engineering</h3>
                                    <p className="cap-text ">
                                      Our experience around API development to support native
                                      applications is with Django (Python) and Ruby on Rails
                                      primarily. While developing APIs, and depending on how far
                                      into the life of a project we are, we make sure to focus on
                                      stability, scalability, performance and ease of integration of
                                      the app.
                                    </p>
                                    <div className="cap-head mb-2">Expertise</div>
                                    <div className="row d-flex justify-content-center">
                                      <div className="d-flex justify-content-center pr-0 cap-wrapper">
                                        <div className="cap-container mt-2">
                                          <p>Python Django</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>RoR</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>Node JS</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>JSON</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>REST</p>
                                        </div>
                                        <div className="cap-container mt-2">
                                          <p>SSL Encryption</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="collapse-scalable"
                        className="panel-collapse collapse tech-section"
                        style={{display: 'none'}}
                      >
                        <div className="container panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div className="process-description">
                                <div className="d-flex d-row">
                                  <div className="container pl-0 pr-0">
                                    <h3 className="cap-headings">
                                      Scalability &amp; Interoperability
                                    </h3>
                                    <p className="cap-text" style={{paddingBottom: '40px'}}>
                                      We design our apps with modularity and a scalable architecture
                                      so that they can be easily integrated with 3rd party Systems
                                      at the time of development or even at a later stage of their
                                      lifecycle. Technology is ever-evolving and it's really
                                      important to adapt to the changing technical landscape, to
                                      this end we follow best practices/industry standards while
                                      designing our systems to make them future proof.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MDBAnimation>
          </div>
        </section>
        <section className="service-text-banner">
          {/* App development Process */}
          <div id="process" className="development-process d-flex justify-content-center">
            <div className="dev-process-desktop development-desktop">
              <div className="row">
                <div className="col-12 wow slideInUp" data-wow-duration="1s">
                  <MDBAnimation type="slideInUp" reveal>
                    <h2 className="heading-desktop heading latoBlack">App Development Process</h2>
                  </MDBAnimation>
                </div>

                {/* pre-kickoff */}

                <PreKickOff />
                {/* pre-kickoff */}
                {/* kickoff */}
                <KickOff />
                {/* kickoff */}
                {/* Increment */}
                <Increment />
                {/* Increment */}
                {/* Delivery */}
                <Delivery />
                {/* Delivery */}
                {/*
            ----------------------------------
            ------KICK OFF DESCRIPTION--------
            ----------------------------------
            */}
                {/* Pre-Kickoff */}

                <div className="col-12 wow slideInUp" data-wow-duration="1s">
                  <div id="pre-kickoff-description" className="process-description">
                    <MDBAnimation type="slideInUp" duration={'1s'} reveal>
                      <div className="d-flex d-row">
                        <div className="circle" style={{backgroundColor: '#0093FF'}} />
                        <div className="description">
                          <h3 className="heading latoBlack mb-3">Pre Kick-off</h3>
                          <p className="latoRegular">
                            We work with the client to analyze project scope and chart down the
                            requirements.
                          </p>
                          <p className="latoRegular">
                            A team is formed that is best suited for the client’s requirements.
                          </p>
                          <p className="latoRegular">
                            We populate the product backlog with epics and user stories, and the
                            client reviews them.
                          </p>
                          <p className="latoRegular">
                            <a href="/project-delivery-process" className="mr-1">
                              {' '}
                              Read More
                            </a>
                            about our pre kick-off delivery process.
                          </p>
                        </div>
                      </div>
                    </MDBAnimation>
                  </div>
                </div>

                {/* Pre-Kickoff */}
                {/* kickoff */}
                <div className="col-12 wow slideInUp" data-wow-duration="1s">
                  <div
                    id="code-description"
                    className="process-description"
                    style={{display: 'none'}}
                  >
                    <div className="d-flex d-row">
                      <div className="circle" style={{backgroundColor: '#0EC573'}} />
                      <div className="description">
                        <h3 className="heading latoBlack mb-3">Kick-off</h3>
                        <p className="latoRegular">
                          We use modified Scrum for project management and delivery of each project.
                        </p>
                        <p className="latoRegular">
                          <a href="/modified-scrum-model" className="mr-1">
                            {' '}
                            Read More
                          </a>
                          on why we modify the Scrum model for each project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* kickoff */}
                {/* Increment */}
                <div className="col-12 wow slideInUp" data-wow-duration="1s">
                  <div
                    id="increment-description"
                    className="process-description"
                    style={{display: 'none'}}
                  >
                    <div className="row">
                      <div className="pl-3">
                        <div className=" circle" style={{backgroundColor: '#F6654D'}} />
                      </div>
                      <div className="col-11 pl-0">
                        <div className="description col-11">
                          <h3 className="heading latoBlack mb-3">Increment and Release cycles</h3>
                          <p className="latoRegular">
                            We deliver an increment at the end of each sprint cycle.
                          </p>
                          <p className="latoRegular">
                            The client chooses the audience they would like the increment to be
                            released to.
                          </p>
                          <p className="latoRegular">
                            Our QA engineers keep track of all the tasks planned for each sprint
                            cycle, their progress, and testing status in a comprehensive sprint
                            report.
                          </p>
                          <p className="latoRegular">
                            Sprint reports are shared with every team member including clients to
                            have complete visibility of the overall progress of sprint.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Increment */}
                {/* Delivery */}
                <div className="col-12 wow slideInUp" data-wow-duration="1s">
                  <div
                    id="delivery-description"
                    className="process-description"
                    style={{display: 'none'}}
                  >
                    <div className="row">
                      <div className="pl-3">
                        <div className="circle" style={{backgroundColor: '#FFB100'}} />
                      </div>
                      <div className="col-11 pl-0">
                        <div className="description">
                          <h3 className="heading latoBlack mb-3">Milestone Delivery</h3>
                          <p className="latoRegular">
                            The increments we deliver build up to a milestone.
                          </p>
                          <p className="latoRegular">
                            Our project managers work with clients across sprint planning and sprint
                            review meetings to develop an understanding of the scope that needs to
                            be covered in a milestone.
                          </p>
                          <p className="latoRegular">
                            Project Managers also make sure that they are aware of important
                            deadlines on which the product has to be demoed to stakeholders or
                            investors, or be released to customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Delivery */}
              </div>
            </div>
            <div className="container dev-process-mobile development-mobile pb-5 pt-1">
              <div className="row">
                <div className="col-12">
                  <h2 className="heading-mobile heading latoBlack">Process</h2>
                </div>
                <div className="col-12">
                  <div className="panel-group" id="accordion">
                    {/* pre kickoff */}
                    <div className="panel panel-default">
                      <div className="panel-heading py-3">
                        <div className="panel-title  text-left">
                          <a
                            className="accordion-toggle collapsed expertise-heading"
                            data-toggle="collapse"
                            href="#collapse-pre"
                          >
                            <img
                              className="img-fluid"
                              src={
                                require('../../assets/images/services/development-process/discover.png')
                                  .default
                              }
                            />
                            <p className="description mb-0">Pre-kickoff</p>
                          </a>
                        </div>
                      </div>
                      <div id="collapse-pre" className="panel-collapse collapse">
                        <div className="panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div id="pre-kickoff-description" className="process-description">
                                <div className="d-flex d-row">
                                  <div className="description">
                                    <h3 className="heading latoBlack mb-3">Pre Kick-off</h3>
                                    <p className="latoRegular">
                                      We work with the client to analyze project scope and chart
                                      down the requirements.
                                    </p>
                                    <p className="latoRegular">
                                      A team is formed that is best suited for the client’s
                                      requirements.
                                    </p>
                                    <p className="latoRegular">
                                      We populate the product backlog with epics and user stories,
                                      and the client reviews them.
                                    </p>
                                    <p className="latoRegular">
                                      <a href="/project-delivery-process" className="mr-1">
                                        {' '}
                                        Read More
                                      </a>
                                      about our pre kick-off delivery process.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-group" id="accordion">
                    {/* Code */}
                    <div className="panel panel-default">
                      <div className="panel-heading py-3">
                        <div className="panel-title  text-left">
                          <a
                            className="accordion-toggle collapsed expertise-heading"
                            data-toggle="collapse"
                            href="#collapse-code"
                          >
                            <img
                              className="img-fluid"
                              src={
                                require('../../assets/images/services/development-process/code.png')
                                  .default
                              }
                            />
                            <p className="description mb-0">Kickoff</p>
                          </a>
                        </div>
                      </div>
                      <div id="collapse-code" className="panel-collapse collapse">
                        <div className="panel-body step-">
                          <div className="row">
                            <div className="col-12">
                              <div id="pre-kickoff-description" className="process-description">
                                <div className="d-flex d-row">
                                  <div className="description">
                                    <h3 className="heading latoBlack mb-3">Kick-off</h3>
                                    <p className="latoRegular">
                                      We use modified Scrum for project management and delivery of
                                      each project
                                    </p>
                                    <p className="latoRegular">
                                      <a href="/modified-scrum-model" className="mr-1">
                                        {' '}
                                        Read More
                                      </a>
                                      on why we modify the Scrum model for each project.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-group" id="accordion">
                    {/* increment */}
                    <div className="panel panel-default">
                      <div className="panel-heading py-3">
                        <div className="panel-title  text-left">
                          <a
                            className="accordion-toggle collapsed expertise-heading"
                            data-toggle="collapse"
                            href="#collapse-increment"
                          >
                            <img
                              className="img-fluid"
                              src={
                                require('../../assets/images/services/development-process/increment.png')
                                  .default
                              }
                            />
                            <p className="description mb-0">Increment &amp; Release Cycles</p>
                          </a>
                        </div>
                      </div>
                      <div id="collapse-increment" className="panel-collapse collapse">
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-12">
                              <div id="pre-kickoff-description" className="process-description">
                                <div className="d-flex d-row">
                                  <div className="description">
                                    <h3 className="heading latoBlack mb-3">
                                      Increment and Release cycles
                                    </h3>
                                    <p className="latoRegular">
                                      We deliver an increment at the end of each sprint cycle.
                                    </p>
                                    <p className="latoRegular">
                                      The client chooses the audience they would like the increment
                                      to be released to.
                                    </p>
                                    <p className="latoRegular">
                                      Our QA engineers keep track of all the tasks planned for each
                                      sprint cycle, their progress, and testing status in a
                                      comprehensive sprint report.
                                    </p>
                                    <p className="latoRegular">
                                      Sprint reports are shared with every team member including
                                      clients to have complete visibility of the overall progress of
                                      sprint.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-group" id="accordion">
                    {/* increment */}
                    <div className="panel panel-default">
                      <div className="panel-heading py-3">
                        <div className="panel-title  text-left">
                          <a
                            className="accordion-toggle collapsed expertise-heading"
                            data-toggle="collapse"
                            href="#collapse-milestone"
                          >
                            <img
                              className="img-fluid"
                              src={
                                require('../../assets/images/services/development-process/milestone.png')
                                  .default
                              }
                            />
                            <p className="description mb-0">Delivery Milestones</p>
                          </a>
                        </div>
                      </div>
                      <div id="collapse-milestone" className="panel-collapse collapse">
                        <div className="panel-body">
                          <div className="row">
                            <div className="col-12">
                              <div id="pre-kickoff-description" className="process-description">
                                <div className="d-flex d-row">
                                  <div className="description">
                                    <h3 className="heading latoBlack mb-3">Milestone Delivery</h3>
                                    <p className="latoRegular">
                                      The increments we deliver build up to a milestone.
                                    </p>
                                    <p className="latoRegular">
                                      Our project managers work with clients across sprint planning
                                      and sprint review meetings to develop an understanding of the
                                      scope that needs to be covered in a milestone.
                                    </p>
                                    <p className="latoRegular">
                                      Project Managers also make sure that they are aware of
                                      important deadlines on which the product has to be demoed to
                                      stakeholders or investors, or be released to customers.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <AllModals/> */}
      </React.Fragment>
    );
  }
}

export default AppDevelopment;
