import React from 'react';
import {MDBAnimation} from 'mdbreact';

export default props => (
  <>
    <div className="col-lg-3 col-12 d-flex wow slideInUp" data-wow-duration="1s">
      <div id="pre-kickoff" className="card pre-kickoff w-100">
        <div className="card-head">
          <p className="float-right latoBlack">1</p>
        </div>
        <div className="card-body">
          <img
            className="img-fluid"
            src={
              require('../../../assets/images/services/development-process/discover.png').default
            }
          />
          <p className="description">Pre-kickoff</p>
        </div>
        <div className="card-footer" />
        <div
          id="pre-kickoff-vertical"
          className="vertical-line"
          style={{borderLeft: '3px dashed #0093FF'}}
        />
      </div>
    </div>
  </>
);
