import React from 'react';
// import { MDBAnimation } from "mdbreact";

export default () => (
  <>
    <div>
      <div className=" android-development reviews-container-desktop">
        {/* <a class="carousel-control-prev" href="#verifiedReviews" data-slide="prev">
         <img alt="right" src={require('../../../assets/images/services/verified-reviews/left.svg') !!}" />
     </a> */}
        <div className="review-card-container">
          <div className=" first-container">
            <h5 className="latoRegular heading"> The Project</h5>
            <h4 className="latoBold heading-descitption">
              iOS, Android &amp; Web Dev for Real Estate Platform
            </h4>
            {/* <h4 class="latoBold heading-descitption">	
				& Development
			</h4> */}
            <p className="latoRegular fact-descitption">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/mobile-development-new.svg')
                    .default
                }
              />
              Web Development
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/confedential-new.svg')
                    .default
                }
              />
              Confidential
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/time-period-new.svg')
                    .default
                }
              />
              Aug. 2018 - Ongoing
            </p>
            <hr className="thematic-break-1" />
            <h5 className="latoBold description-header">Project Summary</h5>
            <p className="latoRegular description ">
              Tintash provides web and mobile app development services for a real estate technology
              platform. In addition to a React-based web app, they built iOS and Android products
              using Swift and Kotlin.{' '}
            </p>
          </div>
          <div className="second-container">
            <div className="all-reviews-container">
              <div className="the-review-container">
                <h5 className="latoRegular heading">The Review</h5>
                <div className="review-text">
                  <p className="latoRegular description-background">
                    [T]intash handled the complexity of our project with relative ease, which not
                    all companies can do.
                  </p>
                </div>
                <div className="date-container">
                  <div className="latoRegular date-text">MAY 29, 2019</div>
                </div>
                {/* <hr class="thematic-break-2"/> */}
              </div>
              <div>
                <div className="rating-container">
                  <p className="latoBold">5.0</p>
                  <div className="star-container">
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                  </div>
                </div>
                <hr className="thematic-break-3" />
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular quality-text">Quality:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular schedule-text">Schedule:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular cost-text">Cost:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                {/* <div>
						<span class="mobile-app-logo"></span>
						<span class="latoRegular" style="margin-right: 60px; font-size: 14px">Quality:</span>
						<span class="latoRegular" style="font-size: 14px">4.0</span>
					</div> */}
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular willing-text">Willing to refer:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
              </div>
            </div>
            <hr className="thematic-break-2" />
            {/* <div class="row">
				<div class="col-md-6 latoItalic">[They] are realistic in what they can do, and don’t oversell themselves.</div>
				
			</div> */}
            <h5 className="latoBold description-header">Feedback Summary</h5>
            <p className="latoRegular description">
              Tintash capably coordinated the development processes of products outside their
              standard portfolio. They offered valuable opinions regarding the practicality of the
              existing designs, and the team is receptive to frequent rounds of feedback. Organized
              and communicative, they are an ideal partner.
            </p>
            <div className="view-full-button">
              <a
                href="https://clutch.co/profile/tintash#review-906645"
                target="_blank"
                className="btn btn-outline-primary"
                role="button"
                aria-pressed="true"
              >
                Read Full Review
              </a>
            </div>
          </div>
          <div className="third-container">
            <h5 className="latoRegular heading "> The Reviewer</h5>
            <div className="heading-descitption">
              <h4 className="latoBold">CTO, Real Estate Technology</h4>
              {/* <p class="latoRegular third-container-review-name">Chris Toepker</p> */}
            </div>
            <hr className="thematic-break-4" />
            <div className="fact-container">
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/building-new.svg')
                      .default
                  }
                />
                <span> Real Estate</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/employee-new.svg')
                      .default
                  }
                />
                <span>11-50 Employees</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/location-new.svg')
                      .default
                  }
                />
                <span> Irvine, California</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/phone-interview-new.svg')
                      .default
                  }
                />
                <span>Phone Review</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/verified-new.svg')
                      .default
                  }
                />
                <span>Verified</span>
              </p>
            </div>
            <hr className="thematic-break-5" />
            <div className="clutch-image">
              <a href="https://clutch.co/profile/tintash" target="_blank">
                <img
                  className="clutchWithouthover"
                  src={
                    require('../../../assets/images/services/verified-reviews/new-clutch-tagline-logo.png')
                      .default
                  }
                />
                <img
                  className="clutchWithHover"
                  src={
                    require('../../../assets/images/services/verified-reviews/clutch-hover.png')
                      .default
                  }
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="reviews-container-mobile">
        <div>
          <div className="d-flex justify-content-between align-items-center review-header-mobile">
            <div className="d-flex rev-mobile-heading">
              <p className="latoRegular development-type">Web Development</p>
            </div>
            <div className="latoRegular time-period time-period-mobile verticalLine">
              MAY 29, 2019
            </div>
          </div>
          <div className="thematic-break-header" />
          <div className="container-heading latoBold mobile-review-description">
            iOS, Android &amp; Web Dev for Real Estate Platform
          </div>
          <div className="latoItalic mobile-description-background">
            "Tintash handled the complexity of our project with relative ease, which not all
            companies can do."
          </div>
          <div className="rating-mobile">
            <p className="latoBold">5.0</p>
            <div className="stars-container-mobile">
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
            </div>
          </div>
          <hr className="thematic-break-mobile" />
          {/* <div class="d-flex justify-content-center align-items-center read-more-mobile">
			<a href="https://clutch.co/profile/tintash#review-906645" target="_blank" >Read More</a>
		</div> */}
          <div className="ratings-for-mobile">
            <div className="rating-container-1">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular quality-text">Quality</span>
                <span className="latoRegular rating-number-text rating-margin">5.0</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular schedule-text">Schedule</span>
                <span className="latoRegular rating-number-text rating-margin">5.0</span>
              </div>
            </div>
            <div className="verticalLine" />
            <div className="rating-container-2">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular cost-text">Cost</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular willing-text">Willing to refer</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
            </div>
          </div>
          <h5 className="latoBold description-header-mobile">Feedback Summary</h5>
          <p className="latoRegular description-mobile">
            Tintash capably coordinated the development processes of products outside their standard
            portfolio. They offered valuable opinions regarding the practicality of the existing
            designs, and the team is receptive to frequent rounds of feedback. Organized and
            communicative, they are an ideal partner.
          </p>
          <div className="owner-header">CTO, Real Estate Technology</div>
          {/* <div class="owner-name">Chris Toepker</div> */}
          <div className="d-flex justify-content-center mt-3 mb-4">
            <img
              className="verified-tick"
              alt="Verified"
              src={require('../../../assets/images/services/verified-reviews/verified.png').default}
            />
            <p className="verified">Verified</p>
          </div>
        </div>
      </div>
    </div>
  </>
);
