import React from 'react';
//import { MDBAnimation } from "mdbreact";
import {Link} from 'gatsby';
//import 'bootstrap';
import './clutch.css';
import ThreedModelling from './ThreedModelling';
import CustomSoftware from './CustomSoftware';
import IosWebRealEstate from './IosWebRealEstate';
import IrVrNational from './IrVrNational';
import LifePrint from './LifePrint';
import StickCricket from './StickCricket';
import AndroidDevelopmentB2b from './AndroidDevelopmentB2b';
import AugmentedIos from './AugmentedIos';
import PlayDate from './PlayDate';
import FounderGameDevelopment from './FounderGameDevelopment';
import SuperBinge from './SuperBinge';
const $ = typeof window !== `undefined` ? require('jquery') : null;

const components = {
  ThreedModelling: ThreedModelling,
  CustomSoftware: CustomSoftware,
  IosWebRealEstate: IosWebRealEstate,
  IrVrNational: IrVrNational,
  LifePrint: LifePrint,
  StickCricket: StickCricket,
  AndroidDevelopmentB2b: AndroidDevelopmentB2b,
  AugmentedIos: AugmentedIos,
  PlayDate: PlayDate,
  FounderGameDevelopment: FounderGameDevelopment,
  SuperBinge: SuperBinge,
};

var flag = 0;
var startItem = 1;
var position = 0;
//var itemCount = $(".carousel__3d li.items").length;
//var leftpos = itemCount;
//var resetCount = itemCount;

class ClutchReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemCount: props.slides.length,
      locations: [],
      leftpos: props.slides.length,
      resetCount: props.slides.length,
    };
  }
  componentDidMount = () => {
    this.interval = setInterval(() => this.swap('clockwise'), 120000);
    var temp = [];
    if (this.state.itemCount > 0) {
      for (let i = 0; i < this.state.itemCount; i++) {
        if (i === 0) {
          temp.push('main-pos');
        } else if (i === 1) {
          temp.push('right-pos');
        } else if (i === this.state.itemCount - 1) {
          temp.push('left-pos');
        } else {
          temp.push('back-pos');
        }
      }
      this.setState({
        locations: temp,
      });
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  previous = () => {
    this.swap('counter-clockwise');
  };
  next = () => {
    this.swap('clockwise');
  };
  incrementAntiClockWise = currentPos => {
    if (currentPos === 'main-pos') {
      return 'right-pos';
    } else if (currentPos === 'right-pos') {
      return 'back-pos';
    } else if (currentPos === 'left-pos') {
      return 'main-pos';
    }
  };
  incrementClockWise = currentPos => {
    if (currentPos === 'main-pos') {
      return 'left-pos';
    } else if (currentPos === 'right-pos') {
      return 'main-pos';
    } else if (currentPos === 'left-pos') {
      return 'back-pos';
    }
  };
  backItemSetting = () => {
    if (flag === 0) {
      flag++;
      return 'left-pos';
    } else {
      return 'back-pos';
    }
  };
  backItemSettingClockWise = () => {
    if (flag === 0) {
      flag++;
      return 'right-pos';
    } else {
      return 'back-pos';
    }
  };
  pos = positionvalue => {
    if (positionvalue != 'leftposition') {
      //increment image list id
      position++;

      //if final result is greater than image count, reset position.
      if (startItem + position > this.state.resetCount) {
        position = 1 - startItem;
      }
    }

    //setting the left positioned item
    if (positionvalue == 'leftposition') {
      //left positioned image should always be one left than main positioned image.
      position = startItem - 1;

      //reset last image in list to left position if first image is in main position
      if (position < 1) {
        position = this.state.itemCount;
      }
    }

    return position;
  };
  swap = direction => {
    if (direction == 'counter-clockwise') {
      var leftitem = 0;
      var old_state = this.state.locations;
      for (let i = 0; i < this.state.locations.length; i++) {
        if (old_state[i] === 'left-pos') {
          leftitem = i - 1;
        }
      }
      if (leftitem === -1) {
        leftitem = this.state.itemCount - 1;
      }
      flag = 0;
      for (let i = 0; i < this.state.locations.length; i++) {
        if (old_state[i] === 'back-pos') {
          if (i === leftitem) {
            old_state[i] = 'left-pos';
          }
          // old_state[i] = this.backItemSetting();
        } else {
          old_state[i] = this.incrementAntiClockWise(old_state[i]);
        }
      }
      this.setState({
        locations: old_state,
      });
      startItem--;
      if (startItem < 1) {
        startItem = this.state.itemCount;
      }
    }
    if (direction == 'clockwise') {
      flag = 0;
      var old_state = this.state.locations;
      /*for(let i=0; i< this.state.locations.length; i++){
                if(old_state[i] === "back-pos"){
                    old_state[i] = this.backItemSettingClockWise();
                }else{
                    old_state[i] = this.incrementClockWise(old_state[i])
                }
            }*/
      old_state[startItem - 1] = 'left-pos';
      old_state[startItem + this.pos() - 1] = 'main-pos';
      old_state[startItem + this.pos() - 1] = 'right-pos';
      old_state[this.pos('leftposition') - 1] = 'back-pos';
      this.setState({
        locations: old_state,
      });

      startItem++;
      position = 0;
      if (startItem > this.state.itemCount) {
        startItem = 1;
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center constant-height">
          <div className="d-flex align-items-center">
            <img
              alt="left"
              src={require('../../../assets/images/services/verified-reviews/left.svg').default}
              value="Prev"
              id="prev"
              className="button-left"
              onClick={this.previous}
            />
          </div>
          <ul className="carousel__3d" id="listCont">
            {this.state.locations &&
              this.props.slides.map((Slide, index) => {
                const SlideWidget = React.createElement(components[Slide]);
                if (index === 0) {
                  return (
                    <li
                      key={Slide}
                      className={`items ${this.state.locations[index]}`}
                      id={index + 1}
                    >
                      {SlideWidget}
                    </li>
                  );
                } else if (index === 1) {
                  return (
                    <li
                      key={Slide}
                      className={`items ${this.state.locations[index]}`}
                      id={index + 1}
                    >
                      {SlideWidget}
                    </li>
                  );
                } else if (index === this.props.slides.length - 1) {
                  return (
                    <li
                      key={Slide}
                      className={`items ${this.state.locations[index]}`}
                      id={index + 1}
                    >
                      {SlideWidget}
                    </li>
                  );
                } else if (index > 1 && index < this.props.slides.length - 1) {
                  return (
                    <li
                      key={Slide}
                      className={`items ${this.state.locations[index]}`}
                      id={index + 1}
                    >
                      {SlideWidget}
                    </li>
                  );
                }
              })}
          </ul>
          <div className="d-flex align-items-center">
            <img
              alt="right"
              src={require('../../../assets/images/services/verified-reviews/right.svg').default}
              value="next"
              id="next"
              className="button-right"
              onClick={this.next}
            />
            {/* <input type="button" value="Next" id="next" class="button-right"> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClutchReview;
