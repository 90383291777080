import React from 'react';
// import { MDBAnimation } from "mdbreact";

export default () => (
  <>
    <div>
      <div className=" android-development reviews-container-desktop">
        {/* <a class="carousel-control-prev" href="#verifiedReviews" data-slide="prev">
         <img alt="right" src={require('../../../assets/images/services/verified-reviews/left.svg') !!}" />
     </a> */}
        <div className="review-card-container">
          <div className=" first-container">
            <h5 className="latoRegular heading"> The Project</h5>
            <h4 className="latoBold heading-descitption">Mobile Game Design &amp; Development</h4>
            {/* <h4 class="latoBold heading-descitption">	
				& Development
			</h4> */}
            <p className="latoRegular fact-descitption">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/mobile-development-new.svg')
                    .default
                }
              />
              Mobile App Development
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/confedential-new.svg')
                    .default
                }
              />
              $200,000 to $999,999
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/time-period-new.svg')
                    .default
                }
              />
              2010 - Ongoing
            </p>
            <hr className="thematic-break-1" />
            <h5 className="latoBold description-header">Project Summary</h5>
            <p className="latoRegular description ">
              Tintash uses premade designs to develop codebases for mobile sports games. The team
              provides development resources along with some graphics and QA/maintenance.
            </p>
          </div>
          <div className="second-container">
            <div className="all-reviews-container">
              <div className="the-review-container">
                <h5 className="latoRegular heading">The Review</h5>
                <div className="review-text">
                  <p className="latoRegular description-background">
                    [T]hey are realistic in what they can do, and don’t oversell themselves.
                  </p>
                </div>
                <div className="date-container">
                  <div className="latoRegular date-text">OCT 31, 2017</div>
                </div>
                {/* <hr class="thematic-break-2"/> */}
              </div>
              <div>
                <div className="rating-container">
                  <p className="latoBold">5.0</p>
                  <div className="star-container">
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="star"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="star"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="star"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="star"
                    />
                  </div>
                </div>
                <hr className="thematic-break-3" />
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular quality-text">Quality:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular schedule-text">Schedule:</span>
                  <span className="latoRegular rating-number-text">4.0</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular cost-text">Cost:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                {/* <div>
						<span class="mobile-app-logo"></span>
						<span class="latoRegular" style="margin-right: 60px; font-size: 14px">Quality:</span>
						<span class="latoRegular" style="font-size: 14px">4.0</span>
					</div> */}
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular willing-text">Willing to refer:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
              </div>
            </div>
            <hr className="thematic-break-2" />
            {/* <div class="row">
				<div class="col-md-6 latoItalic">[They] are realistic in what they can do, and don’t oversell themselves.</div>
				
			</div> */}
            <h5 className="latoBold description-header">Feedback Summary</h5>
            <p className="latoRegular description">
              Tintash’s contribution to the games’ success is tangible, with one of the apps
              receiving 25 million downloads. The team excels at general project management,
              particularly meeting project timelines and understanding particular business needs of
              their clients.{' '}
            </p>
            <div className="view-full-button">
              <a
                href="https://clutch.co/profile/tintash#review-261975"
                target="_blank"
                className="btn btn-outline-primary"
                role="button"
                aria-pressed="true"
              >
                Read Full Review
              </a>
            </div>
          </div>
          <div className="third-container">
            <h5 className="latoRegular heading "> The Reviewer</h5>
            <div className="heading-descitption">
              <h4 className="latoBold">CEO, Stick Sports Ltd.</h4>
              <p className="latoRegular third-container-review-name">Paul Collins</p>
            </div>
            <hr className="thematic-break-4" />
            <div className="fact-container">
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/building-new.svg')
                      .default
                  }
                />
                <span> Computer Software</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/employee-new.svg')
                      .default
                  }
                />
                <span> 11-50 Employees</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/location-new.svg')
                      .default
                  }
                />
                <span>London, United Kingdom</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/phone-interview-new.svg')
                      .default
                  }
                />
                <span>Phone Interview</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/verified-new.svg')
                      .default
                  }
                />
                <span> Verified</span>
              </p>
            </div>
            <hr className="thematic-break-5" />
            <div className="clutch-image">
              <a href="https://clutch.co/profile/tintash" target="_blank">
                <img
                  className="clutchWithouthover"
                  src={
                    require('../../../assets/images/services/verified-reviews/new-clutch-tagline-logo.png')
                      .default
                  }
                />
                <img
                  className="clutchWithHover"
                  src={
                    require('../../../assets/images/services/verified-reviews/clutch-hover.png')
                      .default
                  }
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="reviews-container-mobile">
        <div>
          <div className="d-flex justify-content-between align-items-center review-header-mobile">
            <div className="d-flex rev-mobile-heading">
              <p className="latoRegular development-type">Mobile App Development</p>
            </div>
            <div className="latoRegular time-period time-period-mobile verticalLine">
              OCT 31, 2017
            </div>
          </div>
          <div className="thematic-break-header" />
          <div className="container-heading latoBold mobile-review-description">
            Mobile Game Design &amp; Development
          </div>
          <div className="latoItalic mobile-description-background">
            "They are realistic in what they can do, and don’t oversell themselves."
          </div>
          <div className="rating-mobile">
            <p className="latoBold">5.0</p>
            <div style={{}}>
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
            </div>
          </div>
          <hr className="thematic-break-mobile" />
          <div className="ratings-for-mobile">
            <div className="rating-container-1">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular quality-text">Quality</span>
                <span className="latoRegular rating-number-text rating-margin">5.0</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular schedule-text">Schedule</span>
                <span className="latoRegular rating-number-text rating-margin">4.0</span>
              </div>
            </div>
            <div className="verticalLine" />
            <div className="rating-container-2">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular cost-text">Cost</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular willing-text">Willing to refer</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
            </div>
          </div>
          <h5 className="latoBold description-header-mobile">Feedback Summary</h5>
          <p className="latoRegular description-mobile">
            Tintash’s contribution to the games’ success is tangible, with one of the apps receiving
            25 million downloads. The team excels at general project management, particularly
            meeting project timelines and understanding particular business needs of their clients.{' '}
          </p>
          <div className="owner-header">CEO, Stick Sports Ltd.</div>
          <div className="owner-name">Paul Collins</div>
          <div className="d-flex justify-content-center mt-3 mb-4">
            <img
              className="verified-tick"
              alt="Verified"
              src={require('../../../assets/images/services/verified-reviews/verified.png').default}
            />
            <p className="verified">Verified</p>
          </div>
        </div>
      </div>
    </div>
  </>
);
