import React from 'react';
// import { MDBAnimation } from "mdbreact";

export default () => (
  <>
    <div>
      <div className=" android-development reviews-container-desktop">
        {/* <a class="carousel-control-prev" href="#verifiedReviews" data-slide="prev">
         <img alt="right" src={require('../../../assets/images/services/verified-reviews/left.svg') !!}" />
     </a> */}
        <div className="review-card-container">
          <div className=" first-container">
            <h5 className="latoRegular heading"> The Project</h5>
            <h4 className="latoBold heading-descitption">
              Augmented Mobile Game Development for All Platforms
            </h4>
            {/* <h4 class="latoBold heading-descitption">	
				& Development
			</h4> */}
            <p className="latoRegular fact-descitption">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/mobile-development-new.svg')
                    .default
                }
              />
              Mobile App Development
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/confedential-new.svg')
                    .default
                }
              />
              $50,000 to $199,999
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/time-period-new.svg')
                    .default
                }
              />
              Feb. 2015 - Ongoing
            </p>
            <hr className="thematic-break-1" />
            <h5 className="latoBold description-header">Project Summary</h5>
            <p className="latoRegular description ">
              As an outsourced provider, Tintash helps build mobile games in Unity and
              C#—particularly backend and overall infrastructure development.
            </p>
          </div>
          <div className="second-container">
            <div className="all-reviews-container">
              <div className="the-review-container">
                <h5 className="latoRegular heading">The Review</h5>
                <div className="review-text">
                  <p className="latoRegular description-background">
                    [T]hey contribute to our overall efficiency and ability to deliver.
                  </p>
                </div>
                <div className="date-container">
                  <div className="latoRegular date-text">DEC 11, 2017</div>
                </div>
                {/* <hr class="thematic-break-2"/> */}
              </div>
              <div>
                <div className="rating-container">
                  <p className="latoBold">5.0</p>
                  <div className="star-container">
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                  </div>
                </div>
                <hr className="thematic-break-3" />
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular quality-text">Quality:</span>
                  <span className="latoRegular rating-number-text">4.5</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular schedule-text">Schedule:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular cost-text">Cost:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                {/* <div>
						<span class="mobile-app-logo"></span>
						<span class="latoRegular" style="margin-right: 60px; font-size: 14px">Quality:</span>
						<span class="latoRegular" style="font-size: 14px">4.0</span>
					</div> */}
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular willing-text">Willing to refer:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
              </div>
            </div>
            <hr className="thematic-break-2" />
            {/* <div class="row">
				<div class="col-md-6 latoItalic">[They] are realistic in what they can do, and don’t oversell themselves.</div>
				
			</div> */}
            <h5 className="latoBold description-header">Feedback Summary</h5>
            <p className="latoRegular description">
              Adding the external team has greatly expedited the release of more games. Tintash’s
              team has sometimes not tested as much as expected, but overall, the quality of their
              deliverables has been high and they are delivered in a timely fashion.
            </p>
            <div className="view-full-button">
              <a
                href="https://clutch.co/profile/tintash#review-302991"
                target="_blank"
                className="btn btn-outline-primary"
                role="button"
                aria-pressed="true"
                style={{
                  padding: '10px 29px',
                  color: '#0e99ff',
                  borderColor: '#0e99ff',
                  fontSize: '14px',
                }}
              >
                Read Full Review
              </a>
            </div>
          </div>
          <div className="third-container">
            <h5 className="latoRegular heading "> The Reviewer</h5>
            <div className="heading-descitption">
              <h4 className="latoBold">Founder, Game Development Co</h4>
              {/* <p class="latoRegular third-container-review-name">Lionel Koh</p> */}
            </div>
            <hr className="thematic-break-4" />
            <div className="fact-container">
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/building-new.svg')
                      .default
                  }
                />
                <span> Computer Software</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/employee-new.svg')
                      .default
                  }
                />
                <span> 1-10 Employees</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/location-new.svg')
                      .default
                  }
                />
                <span>San Francisco Bay Area</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/phone-interview-new.svg')
                      .default
                  }
                />
                <span>Phone Interview</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/verified-new.svg')
                      .default
                  }
                />
                <span> Verified</span>
              </p>
            </div>
            <hr className="thematic-break-5" />
            <div className="clutch-image">
              <a href="https://clutch.co/profile/tintash" target="_blank">
                <img
                  className="clutchWithouthover"
                  src={
                    require('../../../assets/images/services/verified-reviews/new-clutch-tagline-logo.png')
                      .default
                  }
                />
                <img
                  className="clutchWithHover"
                  src={
                    require('../../../assets/images/services/verified-reviews/clutch-hover.png')
                      .default
                  }
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="reviews-container-mobile">
        <div>
          <div className="d-flex justify-content-between align-items-center review-header-mobile">
            <div className="d-flex rev-mobile-heading">
              <p className="latoRegular development-type">Mobile App Development</p>
            </div>
            <div className="latoRegular time-period time-period-mobile verticalLine">
              DEC 11, 2017
            </div>
          </div>
          <div className="thematic-break-header" />
          <div className="container-heading latoBold mobile-review-description">
            Augmented Mobile Game Development for All Platforms
          </div>
          <div className="latoItalic mobile-description-background">
            "They contribute to our overall efficiency and ability to deliver."
          </div>
          <div className="rating-mobile">
            <p className="latoBold">5.0</p>
            <div style={{}}>
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
            </div>
          </div>
          <hr className="thematic-break-mobile" />
          <div className="ratings-for-mobile">
            <div className="rating-container-1">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular quality-text">Quality</span>
                <span className="latoRegular rating-number-text rating-margin">4.5</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular schedule-text">Schedule</span>
                <span className="latoRegular rating-number-text rating-margin">5.0</span>
              </div>
            </div>
            <div className="verticalLine" />
            <div className="rating-container-2">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular cost-text">Cost</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular willing-text">Willing to refer</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
            </div>
          </div>
          <h5 className="latoBold description-header-mobile">Feedback Summary</h5>
          <p className="latoRegular description-mobile">
            Adding the external team has greatly expedited the release of more games. Tintash’s team
            has sometimes not tested as much as expected, but overall, the quality of their
            deliverables has been high and they are delivered in a timely fashion.
          </p>
          <div className="owner-header">Founder, Game Development Co.</div>
          {/* <div class="owner-name">Lionel Koh</div> */}
          <div className="d-flex justify-content-center mt-3 mb-4">
            <img
              className="verified-tick"
              alt="Verified"
              src={require('../../../assets/images/services/verified-reviews/verified.png').default}
            />
            <p className="verified">Verified</p>
          </div>
        </div>
      </div>
    </div>
  </>
);
