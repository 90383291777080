import React from 'react';
import {MDBAnimation} from 'mdbreact';

export default props => (
  <>
    <div className="col-lg-3 col-12 d-flex wow slideInUp" data-wow-duration="1s">
      <div id="code" className="card code w-100">
        <div className="card-head">
          <p className="float-right latoBlack">2</p>
        </div>
        <div className="card-body">
          <img
            className="img-fluid"
            src={require('../../../assets/images/services/development-process/code.png').default}
          />
          <p className="description">Kickoff</p>
        </div>
        <div className="card-footer" />
        <div
          id="code-vertical"
          className="vertical-line"
          style={{borderLeft: '3px dashed #0EC573'}}
        />
      </div>
    </div>
  </>
);
