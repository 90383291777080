import React from 'react';
import {MDBAnimation} from 'mdbreact';

export default props => (
  <>
    <div className="col-lg-3 col-12 d-flex wow slideInUp" data-wow-duration="1s">
      <div id="delivery" className="card delivery in w-100">
        <div className="card-head">
          <p className="float-right latoBlack">4</p>
        </div>
        <div className="card-body">
          <img
            className="img-fluid"
            src={
              require('../../../assets/images/services/development-process/milestone.png').default
            }
          />
          <p className="latoBold description mb-0">Delivery Milestones </p>
        </div>
        <div className="card-footer" />
        <div
          id="delivery-vertical"
          className="vertical-line"
          style={{borderLeft: '3px dashed #FFB100'}}
        />
      </div>
    </div>
  </>
);
