import React from 'react';
import {MDBAnimation} from 'mdbreact';

export default props => (
  <>
    <div className="col-lg-3 col-12 d-flex wow slideInUp" data-wow-duration="1s">
      <div id="increment" className="card increment w-100">
        <div className="card-head">
          <p className="float-right latoBlack">3</p>
        </div>
        <div className="card-body">
          <img
            className="img-fluid"
            src={
              require('../../../assets/images/services/development-process/increment.png').default
            }
          />
          <p className="description mb-0 pt-0">Increment &amp; Release Cycles</p>
        </div>
        <div className="card-footer" />
      </div>
      <div
        id="increment-vertical"
        className="vertical-line"
        style={{borderLeft: '3px dashed #F6654D'}}
      />
    </div>
  </>
);
