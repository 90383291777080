import React from 'react';
// import { MDBAnimation } from "mdbreact";

export default () => (
  <>
    <div>
      <div className=" android-development reviews-container-desktop">
        {/* <a class="carousel-control-prev" href="#verifiedReviews" data-slide="prev">
         <img alt="right" src={require('../../../assets/images/services/verified-reviews/left.svg') } />
     </a> */}
        <div className="review-card-container">
          <div className=" first-container">
            <h5 className="latoRegular heading"> The Project</h5>
            <h4 className="latoBold heading-descitption">
              iOS &amp; AR/VR Dev &amp; Prototyping for National Retail Chain
            </h4>
            {/* <h4 class="latoBold heading-descitption">	
				& Development
			</h4> */}
            <p className="latoRegular fact-descitption">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/mobile-development-new.svg')
                    .default
                }
              />
              AR/VR Development
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/confedential-new.svg')
                    .default
                }
              />
              Confidential
            </p>
            <p className="latoRegular fact-descitption ">
              <img
                src={
                  require('../../../assets/images/services/verified-reviews/time-period-new.svg')
                    .default
                }
              />
              Mar. 2017 - Ongoing
            </p>
            <hr className="thematic-break-1" />
            <h5 className="latoBold description-header">Project Summary</h5>
            <p className="latoRegular description ">
              Tintash provides development services on a range of projects for a national retailer.
              Their deliverables have included iOS mobile applications, AR/VR products and more.
            </p>
          </div>
          <div className="second-container">
            <div className="all-reviews-container">
              <div className="the-review-container">
                <h5 className="latoRegular heading">The Review</h5>
                <div className="review-text">
                  <p className="latoRegular description-background">
                    [I] am fond of the quality of their work and their willingness to be present as
                    a team member.
                  </p>
                </div>
                <div className="date-container">
                  <div className="latoRegular date-text">MAY 24, 2019</div>
                </div>
                {/* <hr class="thematic-break-2"/> */}
              </div>
              <div>
                <div className="rating-container">
                  <p className="latoBold">5.0</p>
                  <div className="star-container">
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                    <img
                      className="img-fluid star-dimension"
                      src={require('../../../assets/images/misc/review-star.png').default}
                      alt="bedbathbeyond"
                    />
                  </div>
                </div>
                <hr className="thematic-break-3" />
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular quality-text">Quality:</span>
                  <span className="latoRegular rating-number-text">4.5</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular schedule-text">Schedule:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular cost-text">Cost:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
                {/* <div>
						<span class="mobile-app-logo"></span>
						<span class="latoRegular" style="margin-right: 60px; font-size: 14px">Quality:</span>
						<span class="latoRegular" style="font-size: 14px">4.0</span>
					</div> */}
                <div>
                  <span className="mobile-app-logo" />
                  <span className="latoRegular willing-text">Willing to refer:</span>
                  <span className="latoRegular rating-number-text">5.0</span>
                </div>
              </div>
            </div>
            <hr className="thematic-break-2" />
            {/* <div class="row">
				<div class="col-md-6 latoItalic">[They] are realistic in what they can do, and don’t oversell themselves.</div>
				
			</div> */}
            <h5 className="latoBold description-header">Feedback Summary</h5>
            <p className="latoRegular description">
              Tintash has proven to be a reliable and committed development partner. They strive to
              deliver high-quality work on time. They are detail-oriented and make a concerted
              effort to integrate with existing teams. Tintash’s technical skill set is impressive,
              but their loyalty is most commendable.
            </p>
            <div className="view-full-button">
              <a
                href="https://clutch.co/profile/tintash#review-902004"
                target="_blank"
                className="btn btn-outline-primary"
                role="button"
                aria-pressed="true"
              >
                Read Full Review
              </a>
            </div>
          </div>
          <div className="third-container">
            <h5 className="latoRegular heading "> The Reviewer</h5>
            <div className="heading-descitption">
              <h4 className="latoBold">Head of Innovations, Bed Bath &amp; Beyond</h4>
              <p className="latoRegular third-container-review-name">Amra Tareen</p>
            </div>
            <hr className="thematic-break-4" />
            <div className="fact-container">
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/building-new.svg')
                      .default
                  }
                />
                <span> Retail</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/employee-new.svg')
                      .default
                  }
                />
                <span> 10,000+ Employees</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/location-new.svg')
                      .default
                  }
                />
                <span>San Francisco, California</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/phone-interview-new.svg')
                      .default
                  }
                />
                <span>Phone Interview</span>
              </p>
              <p className="latoRegular fact-descitption ">
                <img
                  src={
                    require('../../../assets/images/services/verified-reviews/verified-new.svg')
                      .default
                  }
                />
                <span> Verified</span>
              </p>
            </div>
            <hr className="thematic-break-5" />
            <div className="clutch-image">
              <a href="https://clutch.co/profile/tintash" target="_blank">
                <img
                  className="clutchWithouthover"
                  src={
                    require('../../../assets/images/services/verified-reviews/new-clutch-tagline-logo.png')
                      .default
                  }
                />
                <img
                  className="clutchWithHover"
                  src={
                    require('../../../assets/images/services/verified-reviews/clutch-hover.png')
                      .default
                  }
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="reviews-container-mobile">
        <div>
          <div className="d-flex justify-content-between align-items-center review-header-mobile">
            <div className="d-flex rev-mobile-heading">
              <p className="latoRegular development-type"> AR/VR Development</p>
            </div>
            <div className="latoRegular time-period time-period-mobile verticalLine">
              MAY 24, 2019
            </div>
          </div>
          <div className="thematic-break-header" />
          <div className="container-heading latoBold mobile-review-description">
            iOS &amp; AR/VR Dev &amp; Prototyping for National Retail Chain
          </div>
          <div className="latoItalic mobile-description-background">
            "I am fond of the quality of their work and their willingness to be present as a team
            member."
          </div>
          <div className="rating-mobile">
            <p className="latoBold">5.0</p>
            <div className="stars-container-mobile">
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="star"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
              <img
                className="img-fluid star-dimension"
                src={require('../../../assets/images/misc/review-star.png').default}
                alt="bedbathbeyond"
              />
            </div>
          </div>
          <hr className="thematic-break-mobile" />
          <div className="ratings-for-mobile">
            <div className="rating-container-1">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular quality-text">Quality</span>
                <span className="latoRegular rating-number-text rating-margin">4.5</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular schedule-text">Schedule</span>
                <span className="latoRegular rating-number-text rating-margin">5.0</span>
              </div>
            </div>
            <div className="verticalLine" />
            <div className="rating-container-2">
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular cost-text">Cost</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
              <hr className="horizontal-line-mobile" />
              <div>
                <span className="mobile-app-logo" />
                <span className="latoRegular willing-text">Willing to refer</span>
                <span className="latoRegular rating-number-text">5.0</span>
              </div>
            </div>
          </div>
          <h5 className="latoBold description-header-mobile">Feedback Summary</h5>
          <p className="latoRegular description-mobile">
            Tintash has proven to be a reliable and committed development partner. They strive to
            deliver high-quality work on time. They are detail-oriented and make a concerted effort
            to integrate with existing teams. Tintash’s technical skill set is impressive, but their
            loyalty is most commendable.
          </p>
          <div className="owner-header">Head of Innovations, Bed Bath &amp; Beyond</div>
          <div className="owner-name">Amra Tareen</div>
          <div className="d-flex justify-content-center mt-3 mb-4">
            <img
              className="verified-tick"
              alt="Verified"
              src={require('../../../assets/images/services/verified-reviews/verified.png').default}
            />
            <p className="verified">Verified</p>
          </div>
          {/* <div class="d-flex justify-content-center align-items-center read-more-mobile">
			<a href="https://clutch.co/profile/tintash#review-902004" target="_blank" >Read More</a>
		</div> */}
        </div>
      </div>
    </div>
  </>
);
